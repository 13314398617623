import React, { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import './PorqueVocePrecisa.css';

function PorQueSiteProfissional() {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  const listItemRef1 = useRef(null);
  const listItemRef2 = useRef(null);
  const listItemRef3 = useRef(null);

  const handleAnimation = (ref, delay) => {
    if (inView) {
      setTimeout(() => {
        ref.current.classList.add('animate');
      }, delay);
    }
  };

  handleAnimation(listItemRef1, 300);
  handleAnimation(listItemRef2, 500);
  handleAnimation(listItemRef3, 700);

  return (
    <div className='porque-precisa-container'>
      <div className='shape-wrapper' ref={ref}>
        <h2>Por que ter um site próprio?</h2>
        <div className='porque-precisa-content'>
          <ul>
            <div>
              <li ref={listItemRef1}><strong>Conexão</strong> Ao fornecer informações estratégicas em um ambiente próprio, os usuários podem se envolver mais profundamente com seu conteúdo e explorar sua oferta de produtos ou serviços.</li>
              <li ref={listItemRef2}><strong>Centralização</strong> Ao centralizar seu conteúdo estratégico em um site próprio, você oferece aos usuários uma experiência coesa e focada. Isso aumenta a eficácia da sua comunicação e te aproxima do seu objetivo.</li>
              <li ref={listItemRef3}><strong>Flexibilidade</strong> Um site ou sistema sob medida pode ser dimensionado e alterado de acordo com as necessidades específicas do seu negócio. A flexibilidade para se adaptar é crucial para manter uma vantagem competitiva.</li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PorQueSiteProfissional;

import React from 'react';
import './Sobre.css';
import Perfil from '../../assets/imgs/1s.webp';

function TornamosSimples() {
  return (
    <div class="sobre-wrapper">

      <div className="shape-small-1"></div>
      <section className='sobre-container reverse'>
        <div className='sobre-content'>
          <h3>Sobre mim</h3>
          <p>Me chamo Jacques Gomes e sou um Desenvolvedor de Software dedicado a construir páginas e sistemas com qualidade e eficiência, seguindo práticas modernas e com uso massivo de tecnologias de ponta.</p>
          <p>Minha abordagem se baseia em entender profundamente as necessidades de cada projeto e buscar as melhores práticas de desenvolvimento para entregar um produto excepcional.</p>
        </div>
        <div className='sobre-img-container'>
          <img className='sobre-img' src={Perfil} alt='monitor img'></img>
        </div>
      </section>
    </div>
  );
}

export default TornamosSimples;

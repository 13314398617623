import "./Footer.css";
import React, { useMemo } from "react";
import Logo from "../../assets/imgs/JG-Símbolo+Logotipo_Branco.svg";
import menuItems from './Btns-Content';
import MenuBtn from './MenuBtn';
import Demo from '../newsletter/Demo';

const Footer = ({ scrollToComponent }) => {

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (

      <MenuBtn className="footer-menu-btn" action={action} data={menuItem} scrollToComponent={scrollToComponent} />

    ));
  }, [menuItems]);

  return (
    <footer className="footer-background">
      <div className="footer-container header-body-footer-width">
        <div class="footer-container-division">

          <div className="footer-wrap footer-content">
            <div className="footer-logo">
              <img className="footer-img" src={Logo} alt="logo"></img>
            </div>
            <div className="footer-menu-container">
              <div className="footer-menu">
                <h5>NAVEGAÇÃO</h5>
                <div>
                  {memoizedMenuItems}
                </div>
              </div>
              <div className="footer-menu">
                <h5>ESPECIALIDADES</h5>
                <div>
                  <p>Desenvolvimento Web</p>
                  <p>Desenvolvimento de Sistemas</p>
                  <p>E-commerce</p>
                  <p>Páginas de venda</p>
                  <p>Páginas institucionais</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrap footer-bottom-container">
          <div className="footer-copy">
            <p>&copy; 2024 Jacques Gomes.</p>
          </div>
          <div className="footer-socials">
            <a
              href="https://www.linkedin.com/in/jacques-gomes-627739240/"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-linkedin-alt"></i>
            </a>

            <a
              href="https://www.instagram.com/jacquesgomess/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-instagram"></i>
            </a>

            <a
              href="https://wa.me/5584994514529"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="uil uil-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import './Servicos.css'; // Estilo CSS para a seção

function ServicesSection() {
  return (
    <section className="services-section">
      <h2>Nossos Serviços</h2>

      <div className="shape-small-1"></div>
      <div className="services-container">
        <div className="service alt-2">
          <h3>Página de Venda</h3>
          <p>
            Converta visitantes em clientes com páginas de venda de alta qualidade e eficiência, projetadas para otimizar as conversões de forma moderna e eficaz.</p>
        </div>

        <div className="service">
          <h3>Loja Online</h3>
          <p>Criamos lojas online com ênfase na qualidade da experiência do usuário e na simplicidade de gestão, utilizando métodos modernos.</p>
        </div>

        <div className="service">
          <h3>
            Sistemas personalizados</h3>
          <p>Desenvolvemos sistemas sob medida para atender às suas necessidades, proporcionando eficiência e escalabilidade para o seu negócio.</p>
        </div>

    
      </div>

    </section>
  );
}

export default ServicesSection;

const type = "Button";

const menuItems = [
    {
        label: "Início",
        type: type,
        to: "inicio",
    },
    {
        label: "Portfólio",
        type: type,
        to: "portfolio",

    },
    {
        label: "Serviços",
        type: type,
        to: "servicos",
    }
];

export default menuItems;

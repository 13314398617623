import React from "react";
import "./Home.css";
import Perfil from "../../assets/imgs/1s.webp";
import Logo from "../../assets/imgs/JG-Símbolo+Logotipo_Branco.svg";

function Home() {
  return (
    <div className="home-wrapper">
      <div className="shapes-wrapper">
        <div className="shape-small"></div>
        <div className="shape-small-1"></div>

        <div className="shape-small-2"></div>
        <div className="home-container">
          <div className="home-content slide-in">
            <h1>
             Faço sites e sistemas sob medida que impulsionam seu negócio!</h1>

            <p>Seja uma página de venda impactante, uma loja online dinâmica ou um sistema de gerenciamento completo, tenho tudo que você precisa para alavancar seus negócios, com qualidade e eficiência!</p>

            <a className="main-btn " href="https://wa.me/5584994514529" target="_blank" rel="noreferrer">Entre em contato</a>
          </div>
          <div className="half-image">
            <div className="overlay"></div>
            <img className="slide-in-right" src={Perfil} alt="perfil"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Home from "./components/home/Home";
import PorqueVocePrecisa from "./components/porqueVocePrecisa/PorqueVocePrecisa";
import Portfolio from "./components/portfolio/Portfolio";
import tornamosSimples from "./components/tornamosSimples/TornamosSimples";
import Depoimentos from "./components/depoimentos/Depoimentos";
import Solucoes from "./components/servicos/Servicos";
import CTA from "./components/CTA/CTA";
import FAQ from "./components/FAQ/FAQ";
import Blog from "./components/blog/Blog";
import Ebook from "./components/Ebook/Ebook";
import CTAFinal from "./components/ctaFinal/CtaFinal";

import "./App.css";
import "./assets/styles/Reset.css";
import TornamosSimples from "./components/tornamosSimples/TornamosSimples";
import Sobre from "./components/sobre/Sobre";
import NotFound from "./components/notfound/NotFound";

function App() {
  const main = process.env.REACT_APP_MAIN;

  const inicio = useRef();
  const portfolio = useRef();
  const blog = useRef();
  const servicos = useRef();
  const ebook = useRef();
  const newsletter = useRef();
  const depoimentos = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      inicio,
      portfolio,
      servicos,
      blog,
      ebook,
      newsletter,
      depoimentos,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "InicioRef") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div className="App">
        <Header scrollToComponent={scrollToComponent} />
        
        <Routes>
          <Route
            path={main}
            element={
              <>
                <div class="body-wrapper">
                  <div ref={inicio}>
                    <Home />
                  </div>

                  <PorqueVocePrecisa />
                  <div ref={portfolio}>
                    <Portfolio />
                  </div>
                  <div ref={servicos}>
                    <Solucoes />
                  </div>
                  {/* <div ref={depoimentos}>
                <Depoimentos />
              // </div> */}
              {/* //     <CTA /> */}
                  <TornamosSimples />
                  <Sobre />
                  {/* <div ref={blog}>
                <Blog />
              </div>
              <div ref={ebook}>
                <Ebook />
              </div> */}

                  <CTAFinal />
                  <FAQ />

                </div>
              </>
            }
          ></Route>

          <Route path="*" element={<NotFound />} />
        </Routes>

        <div ref={newsletter}>
          <Footer scrollToComponent={scrollToComponent} />
        </div>

        <div className="whats-btn">
          <a
            href="https://wa.me/5584994514529"
            target="_blank"
            rel="noreferrer"
          >
            <i class="uil uil-whatsapp whats"></i>
          </a>
        </div>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import '../sobre/Sobre.css';
import Perfil from '../../assets/imgs/Devices-amico.svg';
import './Tornamos.css';

function TornamosSimples() {
  return (
    <div class="sobre-wrapper tornamos-wrapper">

      <div className="shape-small-1"></div>

      <section className='sobre-container'>
        <div className='sobre-content'>
          <h3>Após anos de experiência no mercado, desenvolvi um método exclusivo para a criação de páginas eficientes.</h3>
          <h4>Os pilares do meu método:</h4>          
          <div className='bullets'>
            <div>
              <i class="uil uil-check"></i> <p>Uso de ferramentas modernas</p>
            </div>

            <div>
              <i class="uil uil-check"></i> <p>Design profissional e moderno</p>
            </div>

            <div>
              <i class="uil uil-check"></i> <p>Carregamento rápido</p>
            </div>
            <div>
              <i class="uil uil-check"></i> <p>Foco na experiência do usuário</p>
              
            </div>
            <div>
              <i class="uil uil-check"></i> <p>Decisões orientadas por dados</p>

            </div>
          </div>
          <p>Através da implementação desses princípios, aliado ao uso de tecnologias de ponta e um trabalho individualizado, posso te ajudar a criar páginas e sistemas que trazem resultado.</p>
          
        </div>
        <div className='sobre-img-container'>
          <img src={Perfil} alt='monitor img'></img>
        </div>
      </section>
    </div>
  );
}

export default TornamosSimples;

import React from 'react';
import './CTA.css';

function CTA() {
  return (
    <div className='cta-container final'>
      <div class="cta-content">
        <h2>Vamos conversar!</h2>
        <p>Faça uma consulta gratuita!</p>
        <a href="https://wa.me/5584994514529" target="_blank" rel="noreferrer" className='main-btn-alt'>Entre em Contato</a>

      </div>
    </div>
  );
}

export default CTA;
